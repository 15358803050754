<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall3[4]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
<!--    <van-image width="100%" fit="contain" src="./static/images/customer.png" />-->
    <div class="ScrollBox" style="height: 1200px;background-color: #13171A">
      <div class="listitem" v-for="(user,index) in hmusers" :key="index">
        <div class="robotheader">
          <img :src="'./static/head/'+user.header" />
        </div>
        <div style="margin-left: 20px">{{$t('hall3[5]')}}: </div>
        <div style="margin-left: 20px">{{user.username}} </div>
      </div>
      <div v-if="hmusers.length==0" style="margin: 65px auto;width: 100%;text-align: center">
        {{$t('vanPull[1]')}}
      </div>
    </div>
  </div>
</template>
<script>
  import QRCode from "qrcodejs2";
  import $Dialog from "../common/Dialog";

  export default {
    name: 'Service',
    components: {

    },
    props: ['taskId'],
    data() {
      return {
        showAddress:false,
        service_hotline:'',
        qrcode:null,
        hmusers:[],
      }
    },
    computed: {
    },
    watch: {

    },
    created() {
      var tt = this.taskId;
      this.$Model.GetTaskinfo(this.taskId, data => {
        this.isLoad = false;
        if (data.code == 1) {
          this.hmusers = data.info.users;
        }else{
          $Dialog.Toast(data.code_dec);
        }
      });
    },
    mounted() {

    },
    activated() {

    },
    destroyed() {

    },
    methods: {

    }
  }
</script>
<style scoped>
  .PageBox>>>.van-nav-bar{
    background: #252D30;
  }
  .ScrollBox{
    background-color: #13C465;
    color:#fff;
    margin: 0px auto;
  }

  .PageBox>>>.van-nav-bar{
    background: #252D30;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
  }

  .robotheader{
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }

  .robotheader img {
    width: 100%;
  }

  .listitem {
    display: flex;
    color:#fff;
    margin: 10px auto;
    background-color: #191C23;
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px;
  }
  .QRCode {
    margin-left: 10px;
    height: 230px;
  }

</style>